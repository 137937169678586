.cardLodr {
  height: 82px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .cardLodr {
    height: 110px;
  }
}
